import { Box } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import ModalProvider from "../../contexts/modalContext";
import AuthContentRoutes from "../Content/AuthContentRoutes";
import Content from "../Content/Content";
import ContentRoutes from "../Content/ContentRoutes";
import HeaderRoutes from "../Header/HeaderRoutes";

const WrapperBox = styled(Box)(({ theme }) => ({
  flex: "6",
  background: "#f5f8fa",
  height: "100vh",
  width: "70vw",
  position: "relative",

  [theme.breakpoints.down("md")]: {
    width: "40vw",
  },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  // position: "absolute",
  // top: 0,
  // bottom: 0,
  // left: 0,
  // right: 0,
  // marginTop: "9.5rem",
  // overflowY: "scroll",
}));

const Wrapper = () => {
  return (
    <ModalProvider>
      <WrapperBox>
        <HeaderRoutes />
        <ContentWrapper>
          <Content>
            <AuthContentRoutes />
            <ContentRoutes />
          </Content>
        </ContentWrapper>
      </WrapperBox>
    </ModalProvider>
  );
};

export default Wrapper;
