import jwtDecode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentRole,
  selectCurrentAccessToken,
  selectCurrentUser,
  setCredentials,
} from "../features/auth/authSlice";
import useLocalStorage from "./useLocalStorage";

export const useAuth = () => {
  const accessToken = localStorage.getItem("access") || null;
  const refreshToken = localStorage.getItem("refresh") || null;
  const dispacth = useDispatch();

  //let user_info;

  try {
    const user_info = accessToken ? jwtDecode(accessToken) : null;
    const user = user_info?.username;

    const role = user_info?.role_name;

    const isAuth = Boolean(accessToken);

    dispacth(
      setCredentials({
        user: user_info?.username,
        role: user_info?.role_name,
        accessToken,
        refreshToken,
        isAuth,
      })
    );

    return { user, accessToken, role, isAuth };
  } catch (err) {
    return { user: null, accessToken: null, role: null, isAuth: null };
  }
  //saving refresh token to local storage
};
