import { Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledResponsiveBox = styled(Box)(({ theme }) => ({
  display: "block",
  visibility: "hidden",
  flex: ".5",
  marginRight: "1em",

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export default StyledResponsiveBox;
