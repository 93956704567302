import { common } from "@mui/material/colors";
import React, { lazy, useCallback, useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import authContents, { adminContents } from "../../routes/contentRoutes";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import NotAllowed from "../../pages/auth/NotAllowed";
import { useGetAllUserAccessQuery } from "../../features/permissions/permissionsApiSlice";
import { checkRoutePermitted } from "../../helpers/checkRoutePermitted";
import { adminAsidePages, filterAsidePages } from "../../menu";
import { setPageLoading } from "../../features/app/appSlice";
import { useDispatch } from "react-redux";

const Page404 = lazy(() => import("../../pages/Page404"));

export const StartLoadingPage = () => {
  const dispatch = useDispatch();

  useEffect(() => dispatch(setPageLoading(true)), []);

  return <></>;
};

export const EndLoadingPage = () => {
  const dispatch = useDispatch();

  useEffect(() => dispatch(setPageLoading(false)), []);

  return <></>;
};

const ContentWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  marginTop: "9.5rem",
  overflowY: "scroll",
}));

const ContentRoutes = () => {
  const contents = adminContents;
  const { data, error, isLoading } = useGetAllUserAccessQuery();
  const [filteredMenu, setFilteredMenu] = useState([]);
  useEffect(() => {
    if (!isLoading)
      setFilteredMenu(filterAsidePages(data ?? [], adminAsidePages));
  }, [data, isLoading]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isLoading) dispatch(setPageLoading(1));
    else dispatch(setPageLoading(-1));
    // clean up
  }, [isLoading]);

  const menuListItems = Object.values(filteredMenu);

  return (
    <>
      <Routes>
        {contents.map(({ element, ...content }) => {
          if (checkRoutePermitted(menuListItems, content.path)) {
            return (
              <Route
                key={content.path}
                {...content}
                element={<ContentWrapper>{element}</ContentWrapper>}
              />
            );
          } else {
            return (
              <Route
                key={content.path}
                path={content.path}
                exact
                element={<NotAllowed />}
              />
            );
          }
        })}
      </Routes>
    </>
  );
};

export default ContentRoutes;
