import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { FaHome } from "react-icons/fa";

const StyledHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "1rem",
  paddingRight: "10px",
}));

const StyledHomeIcon = styled(FaHome)(({ theme }) => ({
  marginLeft: 16,
  color: theme.palette.primary.main,
  "&:hover": {
    cursor: "pointer",
  },
  fontSize: 26,
}));

const HomeHeader = () => {
  return (
    <StyledHeader>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
          marginTop: 5,
        }}
      >
        <Typography
          ml={2}
          variant="h1"
          fontWeight={700}
          fontSize={26}
          sx={{ alignSelf: "flex-end" }}
          color="primary.main"
        >
          Home
        </Typography>
        <StyledHomeIcon />
      </Box>
    </StyledHeader>
  );
};

export default HomeHeader;
