import { ReactComponent as Penguin } from "../images/penguin.svg";
import { ReactComponent as Curve } from "../images/curve.svg";

export const SadPenguin = () => {
  return (
    <div
      style={{
        width: "200px",
        height: "230px",
        position: "relative",
      }}
    >
      <Penguin
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,
        }}
      />
      <Curve
        style={{
          position: "absolute",
          top: "20%",
          left: "25%",
          color: "blue",
          zIndex: 2,
        }}
      />
    </div>
  );
};
