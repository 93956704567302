import { ListItem, ListItemText } from "@mui/material";
import { StyledListItemButton, StyledListItemIcon } from "../Aside";
import { NavLink } from "react-router-dom";
import { listItemTextSize } from "./MultiLevelMenu";
import { useSelector, useDispatch } from "react-redux";
import { toggleMobileNav } from "../../../features/app/appSlice";

const SingleLevelMenu = ({ item }) => {
  const dispatch = useDispatch();
  const isMobileNav = useSelector((state) => state.app.mobileNav);

  return (
    <ListItem disablePadding>
      <StyledListItemButton
        component={NavLink}
        activeClassName="active"
        to={item.path}
        onClick={() => isMobileNav && dispatch(toggleMobileNav())}
      >
        <StyledListItemIcon>{item.icon}</StyledListItemIcon>
        <ListItemText
          primaryTypographyProps={{ fontSize: listItemTextSize }}
          color="red"
          primary={item.text}
        />
      </StyledListItemButton>
    </ListItem>
  );
};

export default SingleLevelMenu;
