import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  adminAsidePages,
  authPages,
  filterAsidePages,
  InnerPages,
} from "../../menu";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { FiPower } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../features/auth/authSlice";
import MultiLevelMenu, { listItemTextSize } from "./components/MultiLevelMenu";
import SingleLevelMenu from "./components/SingleLevelMenu";
import logo from "../../res/images/AEM_logo-circle.png";
import { useGetAllUserAccessQuery } from "../../features/permissions/permissionsApiSlice";
import { toggleMobileNav } from "../../features/app/appSlice";

const AsideBodyWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobileNav",
})(({ theme, isMobileNav }) => ({
  top: 0,
  left: 0,
  bottom: 0,
  width: "250px",
  position: "relative",

  [theme.breakpoints.down("md")]: {
    // transform: `translateX(${isMobileNav ? "0" : "-240px"})`,
    display: isMobileNav ? "block" : "none",
    zIndex: 1501,
    width: "60vw",
  },
}));

const HEADER_SIZE = "4.25rem";
export const customIconsStyle = { fontSize: "1.2rem" };

const AsideHeaderWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: HEADER_SIZE,
}));

const AsideWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "absolute",
  overflowY: "scroll",
  overflowX: "hidden",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: "#fff",
  marginTop: HEADER_SIZE,
  flex: "1.1",
  // marginRight: '1rem',
  display: "flex",
  flexDirection: "column",
  transition: "all .2s",
  borderRight: `2px solid ${theme.palette.primary.light}`,
  // alignItems: "center",
  // padding: "0.5rem 0.3rem",
}));

const MobileNavBackground = styled("div")(({ theme }) => ({
  filter: "blur(8px)",
  background: "rgba(0,0,0, 0.4)",
  zIndex: 1500,
  display: "none",
  transition: "all 0.2s",

  [theme.breakpoints.down("md")]: {
    display: "block",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
}));

const StyledHrLine = styled("hr")(({ theme }) => ({
  background: theme.palette.primary.light,
  color: theme.palette.primary.light,
  height: "2px",
  border: "0px solid transparent",
}));

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  fontSize: "12px",
}));

const AsideFooterWrapper = styled(Box)(({ theme }) => ({
  // background: "red",
  // bottom: 0,
  // left: 0,
  // right: 0,
}));

const FooterWrapper = styled("div")(({ theme }) => ({
  //padding: "1em 0",
  paddingBottom: "2em",
  margin: 0,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  //justifyContent: "center",
  alignItems: "center",
  color: theme.palette.primary.main,
  lineHeight: "0.25em",
}));

const BuildNameWrapper = styled("div")(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "0.65rem",
  fontStyle: "italic",
  //marginBottom: "1em",
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: "0.25rem",
  color: theme.palette.primary.main,
  marginBottom: ".4rem",
  transition: "all .2s",
  padding: "0.35em 1em",

  "&:hover,&.active": {
    background: theme.palette.primary.light,
  },
  "&.active": {
    background: theme.palette.primary.main,
  },
  "&.active > *": {
    color: "#fff",
  },
}));

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  fontSize: "1rem",
  // background: "red",
  minWidth: "2.5rem",
  paddingRight: "0",
  marginRight: "0",
  color: theme.palette.primary.main,
}));

const Img = styled("img")({
  height: 50,
  width: 50,
  color: "blue",
  "&:hover": {
    cursor: "pointer",
  },
});

const MenuItem = ({ item }) => {
  const { items, ...rootItem } = item;
  if (items !== undefined && item.items.constructor === Object) {
    // check if dropdown contains items, if not render nothing
    if (Object.values(items).length > 0)
      return <MultiLevelMenu item={{ ...rootItem, items }} />;
    else return <></>;
  } else {
    return <SingleLevelMenu item={item} />;
  }
};

const Aside = () => {
  const dispatch = useDispatch();
  // track menu status
  const isMobileNav = useSelector((state) => state.app.mobileNav);

  let navigate = useNavigate();
  const location = useLocation();
  const { data, error, isLoading } = useGetAllUserAccessQuery();
  const [filteredMenu, setFilteredMenu] = useState([]);
  useEffect(
    () => setFilteredMenu(filterAsidePages(data ?? [], adminAsidePages)),
    [data]
  );

  const menuListItems = Object.values(filteredMenu);

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");

    navigate("/login", { replace: true });
  };
  if (
    data &&
    data.length === 0 &&
    location.pathname !== authPages.NoPermission.path &&
    location.pathname !== InnerPages.Profile.path
  ) {
    navigate("/no-permission", { replace: true });
  }

  return (
    <>
      <MobileNavBackground
        style={{ display: isMobileNav ? "block" : "none" }}
        onClick={() => dispatch(toggleMobileNav())}
      ></MobileNavBackground>
      <AsideBodyWrapper isMobileNav={isMobileNav}>
        <AsideHeaderWrapper>
          <Img
            sx={{
              margin: "9px",
              marginLeft: "2rem",
            }}
            src={logo}
            alt="App Logo"
          />
        </AsideHeaderWrapper>

        <AsideWrapper>
          <List
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                width: "95%",
                paddingLeft: "1rem",
              }}
            >
              <Box style={{ width: "100%", height: "100%" }} component="div">
                {!isLoading ? (
                  menuListItems.map((menuListItem, key) => {
                    return <MenuItem item={menuListItem} key={key} />;
                  })
                ) : (
                  <div></div>
                )}
                {data && data.length > 0 && <StyledHrLine />}
                <ListItem disablePadding>
                  <StyledListItemButton onClick={handleLogout}>
                    <StyledListItemIcon>
                      <FiPower />
                    </StyledListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: listItemTextSize }}
                      primary="Logout"
                    />
                  </StyledListItemButton>
                </ListItem>
                <AsideFooterWrapper>
                  <FooterWrapper>
                    <p style={{ fontWeight: "600" }}>v0.1.0</p>
                    <BuildNameWrapper>Build: Lucy</BuildNameWrapper>
                  </FooterWrapper>
                </AsideFooterWrapper>
              </Box>
            </Box>
          </List>
        </AsideWrapper>
      </AsideBodyWrapper>
    </>
  );
};

export default Aside;
