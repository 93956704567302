import React from "react";
import { Box, Typography } from "@mui/material";
import HandShakeIcon from "@mui/icons-material/HandshakeOutlined";
import { StyledHeader, StyledHeaderIcon } from "./Styles";

const SponsorsHeader = () => {
  return (
    <StyledHeader>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
          marginTop: 5,
        }}
      >
        <Typography
          ml={2}
          variant="h1"
          fontWeight={700}
          fontSize={26}
          sx={{ alignSelf: "flex-end" }}
          color="primary.main"
        >
          Sponsors
        </Typography>
        <StyledHeaderIcon>
          <HandShakeIcon />
        </StyledHeaderIcon>
      </Box>
    </StyledHeader>
  );
};

export default SponsorsHeader;
