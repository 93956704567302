import { FaHome, FaPaperPlane } from "react-icons/fa";
import { FiGrid, FiBook, FiUser, FiShoppingBag, FiUsers } from "react-icons/fi";
import { MdSettings } from "react-icons/md";

import EmailIcon from "@mui/icons-material/EmailOutlined";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendarOutlined";
import HandshakeIcon from "@mui/icons-material/HandshakeOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import KeyIcon from "@mui/icons-material/Key";
import RuleIcon from "@mui/icons-material/Rule";
import { getViewableModules } from "./service/PermissionService";
import { BsCalendar3Event } from "react-icons/bs";
import { GiSecurityGate } from "react-icons/gi";
import { MdOutlineSecurity } from "react-icons/md";
import { customIconsStyle } from "./layout/Aside/Aside";

export const authPages = {
  Login: {
    id: "login",
    text: "Login",
    path: "/login",
    icon: "Login",
  },
  ForgotPassword: {
    id: "forgotPassword",
    text: "ForgotPassword",
    path: "/password/new",
    icon: null,
  },
  ResetPassword: {
    id: "resetPassword",
    text: "Reset Password",
    path: "/reset-password",
    icon: null,
  },
  CheckEmail: {
    id: "checkEmail",
    text: "Check Email",
    path: "/check-email",
    icon: null,
  },
  NoPermission: {
    id: "noPermission",
    text: "No Permission",
    path: "/no-permission",
    icon: null,
  },
  Verify: {
    id: "verify",
    text: "verify",
    path: "/verify",
    icon: null,
  },
  VerifySuccess: {
    id: "verifySuccess",
    text: "verify success",
    path: "/verify-success",
    icon: null,
  },
};

//will later be renamed based on the user role for now
export const adminAsidePages = {
  Home: {
    id: "home",
    module: "DASHBOARD",
    text: "Home",
    path: "/",
    icon: <FaHome />,
  },

  Attendees: {
    id: "attendees",
    module: "CONTACT",
    text: "Contacts",
    path: "/attendees",
    icon: <PermContactCalendarIcon style={customIconsStyle} />,
  },

  Organizations: {
    id: "organizations",
    module: "ORGANIZATION",
    text: "Organizations",
    path: "/organizations",
    icon: <CorporateFareIcon style={customIconsStyle} />,
  },

  Invitation: {
    id: "invitation",
    module: "INVITATION",
    text: "Invitations",
    path: "/invitation",
    icon: <FaPaperPlane />,
  },

  Events: {
    id: "Events",
    module: "EVENT",
    text: "Events",
    path: "/events",
    icon: <BsCalendar3Event />,
  },

  EventRequests: {
    id: "eventRequests",
    module: "EVENT_REQUEST",
    text: "Event requests",
    path: "/event-requests",
    icon: <FiShoppingBag />,
  },

  EventSettings: {
    id: "eventSettings",
    module: "DROPDOWN_EVENTS",
    text: "Event settings",
    path: "/events",
    dropdown: true,
    icon: <FiGrid />,
    items: {
      Categories: {
        id: "categories",
        module: "EVENT_CATEGORY",
        text: "Categories",
        path: "/categories",
        icon: <FiBook />,
      },
      Sponsors: {
        id: "sponsors",
        module: "EVENT_SPONSOR",
        text: "Sponsors",
        path: "/sponsors",
        icon: <HandshakeIcon style={customIconsStyle} />,
      },
      EmailTemplates: {
        id: "EmailTemplates",
        module: "EVENT_EMAIL_TEMPLATE",
        text: "Email Templates",
        path: "/event/templates",
        icon: <EmailIcon style={customIconsStyle} />,
      },
    },
  },

  UserSettings: {
    id: "userSettings",
    module: "DROPDOWN_USERS",
    text: "User settings",
    path: "/users",
    dropdown: true,
    icon: <FiUsers />,
    items: {
      Users: {
        id: "users",
        module: "USER",
        text: "Users",
        path: "/users",
        icon: <FiUser />,
      },
      Roles: {
        id: "roles",
        module: "ROLE",
        text: "Roles",
        path: "/role",
        icon: <KeyIcon style={customIconsStyle} />,
      },
      Permissions: {
        id: "permissions",
        module: "PERMISSION",
        text: "Permissions",
        path: "/permissions",
        icon: <RuleIcon style={customIconsStyle} />,
      },
    },
  },

  Security: {
    id: "security",
    module: "DROPDOWN_SECURITY",
    text: "Security",
    path: null,
    dropdown: true,
    icon: <GiSecurityGate />,
    items: {
      AssignSecurity: {
        id: "Attendance",
        module: "ASSIGN_SECURITY",
        text: "Assign security",
        path: "/event/assign/security",
        icon: <MdOutlineSecurity />,
      },
      Attendance: {
        id: "Attendance",
        module: "ATTENDANCE",
        text: "Attendance",
        path: "/attendance",
        icon: <RemoveRedEyeIcon style={customIconsStyle} />,
      },
    },
  },

  Settings: {
    id: "settings",
    module: "SETTING",
    text: "Settings",
    path: "/settings",
    icon: <MdSettings />,
  },
};

export const rentalCustomerAsidePages = {
  Home: {
    id: "home",
    text: "Home",
    path: "/",
    icon: <FaHome />,
  },
};

export const page404 = {
  id: "404",
  text: "Page 404",
  path: "*",
  icon: "null",
};

export const InnerPages = {
  EventInvitations: {
    path: "/event/invitations",
  },
  Profile: {
    path: "/profile",
  },
  SharedEvents: {
    path: "/event/shared",
  },
  SharedContacts: {
    path: "/attendees/shared",
  },
  AssignSecurity: {
    path: "/event/assign/security",
  },
  Roles: {
    path: "/role",
  },
  Permissions: {
    path: "/permissions",
  },
};

export const publicPages = {
  Subscription: {
    path: "/subscription",
  },
};

export const noAccessReq = [
  "/event/invitations",
  "/profile",
  "/event/shared",
  "/attendees/shared",
  "/no-permission",
  "*",
];

export const filterAsidePages = (permissions, pages) => {
  const modules = getViewableModules(permissions, "READ");
  const values = Object.values(pages);
  const filteredPages = {};

  values.forEach(({ items, ...item }) => {
    if (modules.includes(item.module) || item.dropdown) {
      filteredPages[item.module] = item;
      if (items) {
        filteredPages[item.module].items = filterAsidePages(permissions, items);
      }
    }
  });
  return filteredPages;
};
