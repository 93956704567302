import { CircularProgress, Typography } from "@mui/material";
import React, { Suspense } from "react";
import ContentRoutes from "./ContentRoutes";
import { styled } from "@mui/system";
import AuthContentRoutes from "./AuthContentRoutes";

const ContentWrapper = styled("div")(() => ({
  // border:'1px solid black',
  //minHeight: '100vh'
}));

const Content = () => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ContentWrapper>
        <ContentRoutes />
        <AuthContentRoutes />
      </ContentWrapper>
    </Suspense>
  );
};

export default Content;
