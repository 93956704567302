import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";

const containerStyle = {
  position: "fixed",
  zIndex: 10000,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  background: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

const LoadingPage = () => {
  return (
    <Box style={containerStyle}>
      <CircularProgress />
      <Typography
        color="primary.main"
        variant="h5"
        sx={{ mt: 2, fontWeight: "bold", letterSpacing: "3px" }}
      >
        AEM
      </Typography>
      <Box
        style={{
          postion: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography color="primary.main" variant="p" sx={{ mb: 10 }}>
          Hold on, We are preparing your content.
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadingPage;
