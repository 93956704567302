import {
  InputBase,
  alpha,
  Box,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/system";
import { useField } from "formik";
import React from "react";

export const CustomInputField = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,

    padding: "10px 12px",
    transition: "all",

    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
  ".MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#000",
    color: "#000",
  },
}));

export const InputWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "8px",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: "16px",
  alignSelf: "center",
  textAlign: "right",
  flex: ".5",
  marginRight: 8,
  color: "black",

  [theme.breakpoints.down("md")]: {
    alignSelf: "start",
    textAlign: "left",
    marginBottom: "0.5 em",
    color: "black",
  },
}));

export const CustomInput = ({ label, id, name, ...props }) => {
  const [field, meta] = useField(name);
  let error = meta.touched && meta.error;
  return (
    <Box sx={{ position: "relative" }}>
      {error && (
        <FormHelperText
          sx={{ color: "red", position: "relative", top: "50%", left: "35%" }}
        >
          {meta.error}
        </FormHelperText>
      )}
      <InputWrapper sx={{ m: 0, mb: 1, height: "auto" }}>
        {label && <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>}
        <CustomInputField
          value={field.value}
          {...field}
          {...props}
          sx={{ flex: "1" }}
        />
      </InputWrapper>
    </Box>
  );
};

export default React.forwardRef(CustomInput);
