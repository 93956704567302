import React from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Divider,
  Paper,
  Typography,
  Box,
  Menu,
  MenuItem,
  Button,
  ListItemIcon,
} from "@mui/material";
import { styled } from "@mui/system";
import { FiPower, FiUser, FiUsers } from "react-icons/fi";
import { useAuth } from "../../hooks/useAuth";
import { adminAsidePages, InnerPages } from "../../menu";
import { logout } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineUser } from "react-icons/ai";
import { MdSettings } from "react-icons/md";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { HiOutlineMenu } from "react-icons/hi";
import { toggleMobileNav } from "../../features/app/appSlice";

const HeaderTopWrapper = styled(Paper)(({ theme }) => ({
  padding: " 1rem 2rem ",
  height: "4.25rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  background: theme.palette.primary.main,
  borderRadius: 0,
}));

const StyledMenuItemIcon = styled(ListItemIcon)(({ theme }) => ({
  fontSize: "1rem",
  // background: "red",
  minWidth: "2.5rem",
  paddingRight: "0",
  marginRight: "0",
  color: theme.palette.primary.main,
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.primary.main,
}));

const HeaderTopLeft = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
}));

const StyledMenuIcon = styled(HiOutlineMenu)(({ theme }) => ({
  display: "none",
  color: "#fff",
  fontSize: "2rem",

  "&:hover": {
    cursor: "pointer",
  },
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

const HeaderTopRight = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  justifyContent: "end",
}));

const User = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");

    navigate("/login", { replace: true });
  };
  return (
    <>
      <div>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          Dashboard
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <StyledMenuItem
            onClick={handleClose}
            component={Link}
            to={InnerPages.Profile.path}
            sx={{ fontWeight: 500 }}
          >
            <StyledMenuItemIcon>
              <AiOutlineUser />
            </StyledMenuItemIcon>
            Profile
          </StyledMenuItem>
          <StyledMenuItem
            onClick={handleClose}
            component={Link}
            to={adminAsidePages.Settings.path}
          >
            <StyledMenuItemIcon>
              <MdSettings />
            </StyledMenuItemIcon>
            Settings
          </StyledMenuItem>
          <StyledMenuItem onClick={handleLogout}>
            <StyledMenuItemIcon>
              <FiPower />
            </StyledMenuItemIcon>
            Logout
          </StyledMenuItem>
        </Menu>
      </div>
      <Box
        // component={Link}
        // to={InnerPages.Profile.path}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          textDecoration: "none",
        }}
      >
        <Typography
          fontSize={24}
          fontWeight={600}
          color="primary.typography"
          mr={1}
        >
          {user}
        </Typography>
        <Box
          component="div"
          sx={{ color: "primary.main", display: "flex", alignItems: "center" }}
        >
          <Box>
            <FiUser
              style={{
                fontSize: "24px",
                color: "#fff",
              }}
            />
            <Divider
              color="#fff"
              textAlign="center"
              flexItem={true}
              sx={{ fontSize: "2rem" }}
            />
          </Box>
          <KeyboardArrowDownIcon sx={{ color: "white", ml: "5px" }} />
        </Box>
      </Box>
    </>
  );
};

const HeaderTop = () => {
  const dispatch = useDispatch();
  const isMobileNav = useSelector((state) => state.app.mobileNav);

  return (
    <HeaderTopWrapper elevation={0}>
      <HeaderTopLeft>
        {!isMobileNav && (
          <StyledMenuIcon onClick={() => dispatch(toggleMobileNav())} />
        )}
      </HeaderTopLeft>
      <HeaderTopRight>
        <User />
      </HeaderTopRight>
    </HeaderTopWrapper>
  );
};

export default HeaderTop;
