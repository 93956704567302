import { apiSlice } from "../../app/api/apiSlice";

export const permissionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPermissions: builder.query({
      query: ({ pageIndex, pageSize } = { pageIndex: 0, pageSize: 25 }) => ({
        url: `/portal/permission/role/?page=${pageIndex}&page_size=${pageSize}`,
      }),
      transformResponse: (response) => {
        const { page_metadata, data } = response;
        const permissions = data.map((item) => ({
          id: `${item.module_type}${item.role_id}`,
          role_name: item.role_name,
          role_id: item.role_id,
          module_type: item.module_type,
          permissions: item.permissions,
          read: item.permissions.includes("READ"),
          delete: item.permissions.includes("DELETE"),
          update: item.permissions.includes("UPDATE"),
          create: item.permissions.includes("CREATE"),
        }));
        return {
          data: permissions,
          page_metadata,
        };
      },
      providesTags: ["Permission"],
    }),
    getAllModules: builder.query({
      query: () => ({
        url: "/portal/permission/module/",
      }),
      transformResponse(response) {
        return response;
      },
    }),
    getAllUserAccess: builder.query({
      query: (arg) => ({
        url: "/portal/permission/role/user/",
      }),
      transformResponse(response) {
        return response;
      },
      providesTags: ["ModuleAccess"],
    }),
  }),
});

export const {
  useGetAllPermissionsQuery,
  useGetAllModulesQuery,
  useGetAllUserAccessQuery,
} = permissionsApiSlice;
