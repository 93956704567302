import React, { lazy, useCallback, useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import authContents, { adminContents } from "../../routes/contentRoutes";

const AuthContentRoutes = () => {
  const { isAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  // to get auth contents path ,used slive to remove the last item of the path which is * ,
  const authContentsPath = authContents.map((content) => content.path);
  useEffect(() => {
    if (!isAuth) {
      // to  pervent being redirect in auth pages
      if (authContentsPath.indexOf(location.pathname) !== -1) {
        return;
      }
      navigate("/login");
    }
  }, [isAuth, location.pathname]);

  return (
    <>
      <Routes>
        {authContents.map((content) => (
          <Route key={content.path} {...content} />
        ))}
      </Routes>
    </>
  );
};

export default AuthContentRoutes;
