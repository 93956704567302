import React, { useState } from "react";
import { alpha, styled } from "@mui/system";
import { Box, Paper, Typography, Button, LinearProgress } from "@mui/material";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useSendOtpMutation } from "../../features/auth/authApiSlice";
import { CustomInput } from "../../components/form-components/InputField";
import StyledResponsiveBox from "../../components/form-components/StyledResponsiveBox";
import Toast from "../../components/Toast";
import { useNavigate } from "react-router-dom";
import { authPages } from "../../menu";

const Background = styled(Paper)({
  height: "100vh",
  background:
    "linear-gradient(180deg, #1A6D90 0%, rgba(26, 109, 144, 0.35) 100%)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const StyledContainer = styled(Paper)(({ theme }) => ({
  position: "relative",
  background: "#fff",
  width: "80%",
  maxWidth: "40rem",
  padding: "2em",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    padding: "2em 1em",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#1A6D90",
  color: "#fff",
  fontWeight: "500",
  transition: "all .2s",
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
  },
}));

//validationSchema
const forgotPassowrdSchema = Yup.object().shape({
  recovery_email: Yup.string()
    .email("Must be a valid email address")
    .required("Recovery email is required"),
});

const ForgotPassowrd = () => {
  const [sendOtp, { isLoading, isSuccess, isError }] = useSendOtpMutation();
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      const data = await sendOtp({ email: values.recovery_email }).unwrap();

      navigate(authPages.CheckEmail.path, { replace: true });
    } catch (err) {
      if (err?.status) {
        setErrMsg(err.data.message);
      } else {
        setErrMsg("Something went wrong");
      }
    }
  };

  return (
    <Background>
      {isLoading && (
        <Box sx={{ position: "absolute", top: 0, width: "100%" }}>
          <LinearProgress color="secondary" />
        </Box>
      )}
      <StyledContainer>
        <Typography
          variant="h1"
          align="center"
          sx={{
            color: "#1A6D91",
            fontSize: "40px",
            fontWeight: "600",
            lineHeight: "59px",
            marginBottom: "2rem",
          }}
        >
          Forgot Password
        </Typography>
        {isError && <Toast message={errMsg} />}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Formik
            initialValues={{
              recovery_email: "",
            }}
            validationSchema={forgotPassowrdSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Box
                component={Form}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CustomInput
                  label="Recovery Email"
                  id="recovery_email"
                  name="recovery_email"
                  type="text"
                  placeholder="example@gmail.com"
                />

                <Box
                  sx={{ alignSelf: "flex-end", width: "100%", display: "flex" }}
                >
                  <StyledResponsiveBox />
                  <StyledButton
                    sx={{ flex: 1, mr: 1.8, mt: 2 }}
                    disabled={isLoading}
                    type="submit"
                  >
                    SendLink
                  </StyledButton>
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
      </StyledContainer>
    </Background>
  );
};

export default ForgotPassowrd;
