import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { StyledButton } from "../../../pages/auth/Login";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../contexts/modalContext";
import { StyledHeader, StyledHeaderIcon } from "./Styles";
import { MdOutlineSecurity } from "react-icons/md";

const AssignSecurityHeader = () => {
  const navigate = useNavigate();
  const { toggleModal } = useModal();
  return (
    <StyledHeader>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
          marginTop: 5,
        }}
      >
        <Typography
          ml={2}
          variant="h1"
          fontWeight={700}
          fontSize={26}
          sx={{ alignSelf: "flex-end" }}
          color="primary.main"
        >
          Assign Security
        </Typography>
        <StyledHeaderIcon>
          <MdOutlineSecurity />
        </StyledHeaderIcon>
      </Box>
    </StyledHeader>
  );
};

export default AssignSecurityHeader;
