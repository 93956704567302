import { configureStore } from "@reduxjs/toolkit";
import authReducer, { authMiddleware } from "../features/auth/authSlice";
import { apiSlice } from "./api/apiSlice";
import invitationReducer from "../features/invitations/invitationSlice";
import appReducer from "../features/app/appSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    invitations: invitationReducer,
    app: appReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware).concat(authMiddleware),
  devTools: true,
});
