import Header from "../layout/Header/Header";
import {
  HomeHeader,
  EventsHeader,
  EventRequestsHeader,
  CategoriesHeader,
  InvitationHeader,
  OrganizationsHeader,
  UsersHeader,
  SettingsHeader,
  ProfileHeader,
  SharedEventsHeader,
  SharedContactsHeader,
  AssignSecurityHeader,
} from "../layout/Header/adminPagesHeader";
import { adminAsidePages, InnerPages, authPages } from "../menu";
import AttendeesHeader from "../layout/Header/adminPagesHeader/AtendeesHeader";
import RolesHeader from "../layout/Header/adminPagesHeader/RolesHeader";
import PermissionsHeader from "../layout/Header/adminPagesHeader/PermissionsHeader";
import SponsorsHeader from "../layout/Header/adminPagesHeader/SponsorsHeader";
import AttendanceHeader from "../layout/Header/adminPagesHeader/AttendanceHeader";
import EmailTemplatesHeader from "../layout/Header/adminPagesHeader/EmailTemplatesHeader";
import EventInvitationsHeader from "../layout/Header/adminPagesHeader/EventInvitationsHeader";

const adminHeaders = [
  {
    path: adminAsidePages.Home.path,
    element: <Header headerBottom={<HomeHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.Attendees.path,
    element: <Header headerBottom={<AttendeesHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.Invitation.path,
    element: <Header headerBottom={<InvitationHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.Events.path,
    element: <Header headerBottom={<EventsHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.EventRequests.path,
    element: <Header headerBottom={<EventRequestsHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.EventSettings.items.Categories.path,
    element: <Header headerBottom={<CategoriesHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.EventSettings.items.Sponsors.path,
    element: <Header headerBottom={<SponsorsHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.EventSettings.items.EmailTemplates.path,
    element: <Header headerBottom={<EmailTemplatesHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.UserSettings.items.Users.path,
    element: <Header headerBottom={<UsersHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.UserSettings.items.Roles.path,
    element: <Header headerBottom={<RolesHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.UserSettings.items.Permissions.path,
    element: <Header headerBottom={<PermissionsHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.Security.items.AssignSecurity.path,
    element: <Header headerBottom={<AssignSecurityHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.Security.items.Attendance.path,
    element: <Header headerBottom={<AttendanceHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.Settings.path,
    element: <Header headerBottom={<SettingsHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.Organizations.path,
    element: <Header headerBottom={<OrganizationsHeader />} />,
    exact: true,
  },
  {
    path: authPages.NoPermission.path,
    element: <Header headerBottom={<></>} />,
    exact: true,
  },
  {
    path: InnerPages.Profile.path,
    element: <Header headerBottom={<ProfileHeader />} />,
    exact: true,
  },
  {
    path: InnerPages.EventInvitations.path,
    element: <Header headerBottom={<EventInvitationsHeader />} />,
    exact: true,
  },
  {
    path: InnerPages.SharedEvents.path,
    element: <Header headerBottom={<SharedEventsHeader />} />,
    exact: true,
  },
  {
    path: InnerPages.SharedContacts.path,
    element: <Header headerBottom={<SharedContactsHeader />} />,
    exact: true,
  },
];

const rentalCustomersHeaders = [
  {
    path: adminAsidePages.Home.path,
    element: <Header headerBottom={<HomeHeader />} />,
    exact: true,
  },
];

export { adminHeaders, rentalCustomersHeaders };
