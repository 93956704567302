import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { authPages, publicPages } from "./menu";
// Components
import Aside from "./layout/Aside/Aside";
import Wrapper from "./layout/Wrapper/Wrapper";
import { Box } from "@mui/material";
import { useGetAllSettingsQuery } from "./features/settings/settingsApiSlice";
import LoadingPage from "./pages/LoadingPage";
import { useDispatch, useSelector } from "react-redux";
import { setPageLoading } from "./features/app/appSlice";

const App = () => {
  const pageLoading = useSelector((state) => state.app.pageLoading);
  const { data: settings, isLoading: isSettingsLoading } =
    useGetAllSettingsQuery();
  const [mode, setMode] = useState("light");

  useEffect(() => {
    if (!isSettingsLoading) {
      if (settings?.data.portal_setting.is_dark_mode) {
        setMode("dark");
      } else {
        setMode("light");
      }
    }
  }, [settings]);

  const dispatch = useDispatch();
  useEffect(() => {
    const timer = setTimeout(() => dispatch(setPageLoading(-1)), 1000);
    // clean up
    return () => clearTimeout(timer);
  }, []);

  const withOutAsidePages = [
    authPages.Login.path,
    authPages.ForgotPassword.path,
    authPages.ResetPassword.path,
    authPages.CheckEmail.path,
    authPages.Verify.path,
    authPages.VerifySuccess.path,
    publicPages.Subscription.path,
  ];

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: "#1A6D90",
            light: "#e4eaed",
            typography: "#fff",
            gray: "#808080",
          },
          secondary: {
            light: "#ff7961",
            main: "#ffa700",
            dark: "#ba000d",
          },
          body: {
            secondary: "#f5f8fa",
          },
          mode,
        },
        typography: {
          fontFamily: ["Montserrat"],
        },
      }),
    [mode]
  );

  return (
    <ThemeProvider theme={theme}>
      {pageLoading !== 0 && <LoadingPage />}
      <Box sx={{ display: "flex", width: "100%" }}>
        <Routes>
          {withOutAsidePages.map((path) => (
            <Route key={path} path={path} />
          ))}
          <Route path="*" element={<Aside />} />
        </Routes>
        <Wrapper />
      </Box>
    </ThemeProvider>
  );
};

export default App;
