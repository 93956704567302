import axios from "axios";
import { baseUrl } from "../app/api/apiSlice";

export async function getEvents(token) {
  const config = {
    headers: { Authorization: `JWT ${token}` },
  };
  const res = await axios.get(`${baseUrl}/portal/event/?page=0`, config);
  return res.data.data.data.map((item) => ({
    name: item.name,
    location: getLocation(item.location.coordinates),
    event_type: item.categories.map((item) => item.name).join(","),
    id: item.id,
    originalEvent: item,
    isCanceled: item.is_canceled,
  }));
}

export async function cancelEvent(token, data) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return await axios.put(`${baseUrl}/portal/event/cancel/`, data, config);
}

export async function getInvitation(token, id) {
  const config = {
    headers: { Authorization: `JWT ${token}` },
  };
  const res = await axios.get(
    `${baseUrl}/portal/invitation/detail/?invitation_id=${id}`,
    config
  );
  const data = res.data.data;
  return {
    id: data.id,
    attendee_id: data.attendee.id,
    full_name: data.attendee.full_name,
    phone: data.attendee.phone,
    email: data.attendee.email,
    sex: data.attendee.sex,
    event: data.event.id,
    event_name: data.event.name,
    is_phone_allowed: data.is_phone_allowed,
    is_vip: data.is_vip,
    is_vehicle_allowed: data.is_vehicle_allowed,
    vehicle_plate_number: data.vehicle_plate_number,
  };
}

export async function getEventRequests(token, pagination) {
  const { pageIndex, pageSize } = pagination;
  const config = {
    headers: { Authorization: `JWT ${token}` },
  };
  const res = await axios.get(
    `${baseUrl}/portal/event/request/?page=${pageIndex}&page_size=${pageSize}`,
    config
  );
  const { page_metadata, data } = res.data.data;
  const requests = data.map((item) => ({
    id: item.id,
    name: item.event.name,
    finance: item["finance_requests"],
    ict: item["ict_requests"],
    fleet: item["fleet_requests"].split(",").length + " vehicles",
    date: formatDate(item["date_created"]),
    originalDate: item["date_created"],
    allFleet: item["fleet_requests"],
  }));

  return {
    page_metadata,
    data: requests,
  };
}

function formatDate(date) {
  const obj = new Date(date);
  return obj.toDateString();
}

export async function getEventCategories(token, pagination) {
  const { pageIndex, pageSize } = pagination;
  const config = {
    headers: { Authorization: `JWT ${token}` },
  };
  const res = await axios.get(
    `${baseUrl}/portal/event/category/?page=${pageIndex}&page_size=${pageSize}`,
    config
  );
  const { page_metadata, data } = res.data.data;
  const categories = data.map((item) => ({
    ...item,
    date_created: formatDate(item.date_created),
  }));
  return {
    page_metadata,
    data: categories,
  };
}

export async function getSingleEventCategory(token, id) {
  const config = {
    headers: { Authorization: `JWT ${token}` },
  };
  const res = await axios.get(
    `${baseUrl}/portal/event/category/detail/?event_category_id=${id}`,
    config
  );
  return res.data.data;
}

export async function getSingleEventRequest(token, id) {
  const config = {
    headers: { Authorization: `JWT ${token}` },
  };
  const res = await axios.get(
    `${baseUrl}/portal/event/request/detail/?event_request_id=${id}`,
    config
  );
  const data = res.data.data;
  return {
    ...data,
    finance_requests: data.finance_requests.split(","),
    fleet_requests: data.fleet_requests.split(","),
    ict_requests: data.ict_requests.split(","),
  };
}

export async function updateEventCategory(token, id, data) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const res = await axios.put(
    `${baseUrl}//portal/event/category/update/`,
    {
      ...data,
      event_category_id: id,
    },
    config
  );
  return res.data;
}

export async function updateEventRequest(token, id, data) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const res = await axios.put(
    `${baseUrl}/portal/event/request/update//`,
    {
      ...data,
      event_request_id: id,
    },
    config
  );
  return res.data;
}

export async function updateInvitation(token, id, data) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const res = await axios.put(
    `${baseUrl}/portal/invitation/update/`,
    {
      ...data,
      invitation_id: id,
    },
    config
  );
  return res.data;
}

export async function getInvitations(token, pagination) {
  const { pageIndex, pageSize } = pagination;
  const config = {
    headers: { Authorization: `JWT ${token}` },
  };
  const res = await axios.get(
    `${baseUrl}/portal/invitation/?page=${pageIndex}&page_size=${pageSize}`,
    config
  );
  const { page_metadata, data } = res.data.data;
  const invitations = data.map((item) => ({
    id: item.id,
    name: item.attendee.full_name,
    email: item.attendee.email,
    phone: item.attendee.phone,
    image: item.attendee.image,
    event: item.event.name,
    phone_allowed: item.is_phone_allowed,
    vip: item.is_vip,
    vehicle: item.is_vehicle_allowed,
    plate_number: item.vehicle_plate_number,
    ticket: item.ticket_code,
    invited: false, //TODO update invited
    event_id: item.event.id,
  }));
  return {
    page_metadata,
    data: invitations,
  };
}

export async function createEventRequest(token, data) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return await axios.post(
    `${baseUrl}/portal/event/request/create/`,
    data,
    config
  );
}

export async function createEvent(token, data) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return await axios.post(`${baseUrl}/portal/event/create/`, data, config);
}

export async function editEvent(token, id, data) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return await axios.put(
    `${baseUrl}/portal/event/update/`,
    {
      ...data,
      event_id: id,
    },
    config
  );
}

export async function postponeEvent(token, data) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return await axios.put(`${baseUrl}/portal/event/postpone/`, data, config);
}

export async function getEventRequest(token, data) {}

export async function getAttendees(token, pagination) {
  const { pageIndex, pageSize } = pagination;
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
    },
  };
  const res = await axios.get(
    `${baseUrl}/portal/event/attendee/?page=${pageIndex}&page_size=${pageSize}`,
    config
  );
  const { page_metadata, data } = res.data.data;
  const attendees = data.map((attendee) => ({
    id: attendee.id,
    image: attendee.image,
    full_name: attendee.full_name,
    email: attendee.email,
    phone: attendee.phone,
    sex: attendee.sex,
    organization: attendee.organization?.name,
    orgId: attendee.organization?.id,
    created_by: attendee.created_by.email,
  }));
  return {
    page_metadata,
    data: attendees,
  };
}

export async function getAttendeesWithQuery(token, query) {
  const config = {
    headers: { Authorization: `JWT ${token}` },
  };
  const res = await axios.get(
    `${baseUrl}/portal/event/attendee/?query=${query}&page=0&page_size=5`,
    config
  );
  return res.data.data.data.map((items) => ({
    ...items,
    name: items.full_name,
  }));
}

export async function createAttendee(token, data) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return await axios.post(
    `${baseUrl}/portal/event/attendee/create/`,
    data,
    config
  );
}

export async function deleteAttendee(token, items, password) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
    },
  };
  return await axios.delete(`${baseUrl}/portal/event/attendee/delete/`, {
    headers: config.headers,
    data: {
      attendee_ids: items,
      password,
    },
  });
}

export async function deleteEvent(token, items, password) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
    },
  };
  return await axios.delete(`${baseUrl}/portal/event/delete/`, {
    headers: config.headers,
    data: {
      event_ids: items,
      password,
    },
  });
}

export async function deleteInvitation(token, items, password) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
    },
  };
  return await axios.delete(`${baseUrl}/portal/invitation/delete/`, {
    headers: config.headers,
    data: {
      invitation_ids: items,
      password: password,
    },
  });
}

export async function deleteCategory(token, items, password) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
    },
  };
  return await axios.delete(`${baseUrl}/portal/event/category/delete/`, {
    headers: config.headers,
    data: {
      event_category_ids: items,
      password,
    },
  });
}

export async function deleteEventRequest(token, items, password) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
    },
  };
  return await axios.delete(`${baseUrl}/portal/event/request/delete/`, {
    headers: config.headers,
    data: {
      event_request_ids: items,
      password,
    },
  });
}

export async function getAttendee(token, id) {
  const config = {
    headers: { Authorization: `JWT ${token}` },
  };
  const res = await axios.get(
    `${baseUrl}/portal/event/attendee/detail/?attendee_id=${id}`,
    config
  );
  return res.data.data;
}

export async function getUser(token, id) {
  const config = {
    headers: { Authorization: `JWT ${token}` },
  };
  const res = await axios.get(
    `${baseUrl}/portal/users/User/?user_id=${id}`,
    config
  );
  return res.data.data;
}

export async function createEventCategory(token, data) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return await axios.post(
    `${baseUrl}/portal/event/category/create/`,
    data,
    config
  );
}

export async function createInvitationTemplate(token, data) {}

export async function createInvitation(token, data) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return await axios.post(`${baseUrl}/portal/invitation/create/`, data, config);
}

export async function getSponsors(token, pagination) {
  const { pageIndex, pageSize } = pagination;
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
    },
  };
  const res = await axios.get(
    `${baseUrl}/portal/event/sponsor/?page=${pageIndex}&page_size=${pageSize}`,
    config
  );
  const { page_metadata, data } = res.data.data;
  const sponsors = data;
  return {
    page_metadata,
    data: sponsors,
  };
}

export async function createSponsor(token, data) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return await axios.post(
    `${baseUrl}/portal/event/sponsor/create/`,
    data,
    config
  );
}

export async function updateSponsor(token, id, data) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const res = await axios.put(
    `${baseUrl}/portal/event/sponsor/update/`,
    {
      ...data,
      event_sponsor_id: id,
    },
    config
  );

  return res.data;
}

export async function getSponsor(token, id) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const res = await axios.get(
    `${baseUrl}//portal/event/sponsor/detail/?event_sponsor_id=${id}`,
    config
  );
  return res.data.data;
}

export async function deleteSponsor(token, items, password) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
    },
  };
  return await axios.delete(`${baseUrl}/portal/event/sponsor/delete/`, {
    headers: config.headers,
    data: {
      password,
      event_sponsor_ids: items,
    },
  });
}

export async function getEventInvitations(token, id, pagination) {
  const { pageIndex, pageSize } = pagination;
  const config = {
    headers: { Authorization: `JWT ${token}` },
  };
  const res = await axios.get(
    `${baseUrl}/portal/event/invitations/?event_id=${id}&page=${pageIndex}&page_size=${pageSize}`,
    config
  );
  const { page_metadata, data } = res.data.data;
  const invitations = data.map((item) => ({
    id: item.id,
    name: item.attendee.full_name,
    sex: item.attendee.sex,
    organization: item.attendee.organization,
    image: item.attendee.image,
    phone: item.attendee.phone,
    email: item.attendee.email,
    invited: item.is_email_sent ? "Yes" : "No",
    is_invited: item.is_email_sent,
    is_vehicle_allowed: item.is_vehicle_allowed,
    is_vip: item.is_vip,
    ticket: item.ticket_code,
    rsvp: item?.rsvp.rsvp,
    is_ticket_email_sent: item.is_ticket_email_sent,
  }));
  return {
    page_metadata,
    data: invitations,
  };
}

export async function getEventDetails(token, id) {
  const config = {
    headers: { Authorization: `JWT ${token}` },
  };
  const res = await axios.get(
    `${baseUrl}/portal/event/detail/?event_id=${id}`,
    config
  );
  return res.data.data;
}

export async function sendInvitation(token, data) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return await axios.post(`${baseUrl}/portal/invitation/send/`, data, config);
}

export async function updateTicketScan(token, data) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return await axios.put(
    `${baseUrl}/portal/event/set_allow_ticket_scan/`,
    data,
    config
  );
}

export async function getRentalTypes(token) {}

export async function getGoogleMapApiKey(token) {
  const config = {
    headers: { Authorization: `JWT ${token}` },
  };
  const res = await axios.get(`${baseUrl}/portal/setting/api_key/`, config);
  return res.data.data.google_map_key;
}

export async function massImportAttendees(token, attendee_data, password) {
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const data = {
    attendee_data,
    password,
  };
  return await axios.put(
    `${baseUrl}/portal/event/attendee/import/`,
    data,
    config
  );
}

function getLocation(coordinates) {
  return `${coordinates.lat},${coordinates.lon}`;
}
