import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

// Style
import "./index.css";
import "./styles/font.css";
import "./styles/iconfont/material-icons.css";
import "./styles/iconfont/material-icons-outlined.css";
import "./styles/iconfont/material-icons-round.css";
import "./styles/iconfont/material-icons-sharp.css";
import "./styles/iconfont/material-icons-twotone.css";
// Components
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./app/store";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { refreshAuthLogic } from "./helpers/axiosInterceptors";

// Instantiate the interceptor
createAuthRefreshInterceptor(axios, refreshAuthLogic, {
  statusCodes: [401],
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
        <CssBaseline />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
