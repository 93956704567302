import { styled } from "@mui/system";
import { Box, Paper, Typography } from "@mui/material";
import { SadPenguin } from "../../res/reactions/SadPenguin";

const Background = styled(Paper)({
  height: "100vh",
  background: "white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const NotAllowed = () => {
  return (
    <Background elevation={0}>
      {/* <SadPenguin /> */}
      <Typography color="primary.main" variant="h5">
        You are not authorized to acccess this page.
      </Typography>
      <Typography color="primary.main" variant="h6">
        please contact the administrator.
      </Typography>
    </Background>
  );
};

export default NotAllowed;
