import { apiSlice } from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/users/user/login/",
        method: "POST",
        body: credentials,
      }),
      // invalidatesTags: ["ModuleAccess"],
    }),
    sendOtp: builder.mutation({
      query: (email) => ({
        url: "/users/user/sendForgetPasswordEmailOtp/",
        method: "POST",
        body: email,
      }),
    }),
    resetPassword: builder.mutation({
      query: (credentials) => ({
        url: "/users/user/editPasswordWithEmailOtp/",
        method: "PUT",
        body: credentials,
      }),
    }),
    updateAdminUser: builder.mutation({
      query: (data) => ({
        url: "/users/user/updateProfile/",
        method: "PUT",
        body: data,
      }),
    }),
    updatePassword: builder.mutation({
      query: (credentials) => ({
        url: "/users/user/editPassword/",
        method: "PUT",
        body: credentials,
      }),
    }),
    deleteAccount: builder.mutation({
      query: () => ({
        url: "/users/user/removeAccount/",
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useResetPasswordMutation,
  useLoginMutation,
  useSendOtpMutation,
  useUpdatePasswordMutation,
  useUpdateAdminUserMutation,
  useDeleteAccountMutation,
} = authApiSlice;
