import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logout, setCredentials } from "../../features/auth/authSlice";
import jwtDecode from "jwt-decode";

const url = "https://api.aem.awuraplc.org";

export const baseUrl = url;

export const isExpired = (token) => {
  try {
    const { exp } = jwtDecode(token);
    if (Date.now() >= exp * 1000) {
      return true;
    }
  } catch (err) {
    // todo: handle this exception
    return true;
  }
  return false;
};

const baseQuery = fetchBaseQuery({
  baseUrl: url,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    let accessToken = window.localStorage?.getItem("access");
    let tokenValid = false;
    try {
      jwtDecode(accessToken);
      tokenValid = true;
    } catch (err) {
      localStorage.removeItem("access");
    }
    if (accessToken && tokenValid) {
      headers.set("Authorization", `JWT ${accessToken}`);
    } else {
      headers.delete("Authorization");
    }
    return headers;
  },
});

const execRefreshToken = async (args, api, extraOptions) => {
  const refreshToken = window.localStorage?.getItem("refresh");
  const accessToken = window.localStorage?.getItem("access");
  // send refresh token

  const refreshResultRaw = await fetch(`${url}/api/token/refresh/`, {
    cache: "no-cache",
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify({ refresh: refreshToken }),
  });

  const refreshResult = await refreshResultRaw.json();

  if (refreshResultRaw.status !== 200) {
    api.dispatch(logout());
    return false;
  } else if (refreshResult) {
    window.localStorage.setItem("access", refreshResult.access);
    window.localStorage.setItem("refresh", refreshResult.refresh);
    const user = api.getState().auth.user;
    //store the new token
    const res = await api.dispatch(
      setCredentials({
        ...refreshResult,
        accessToken: refreshResult.access,
        refreshToken: refreshResult.refresh,
        user,
      })
    );
    return true;
  } else {
    api.dispatch(logout());
    return false;
  }
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    const isRefreshed = await execRefreshToken(args, api, extraOptions);
    if (isRefreshed) {
      // re submit request
      result = await baseQuery(args, api, extraOptions);
      return result;
    } else {
      return;
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Security",
    "Event",
    "SharedEvents",
    "Event Request",
    "Invitation",
    "Role",
    "User",
    "Attendance",
    "Attendee",
    "SharedAttendee",
    "Email Template",
    "Permission",
    "ModuleAccess",
  ],
  endpoints: (builder) => ({}),
});
