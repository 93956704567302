import React, { lazy } from "react";
import {
  authPages,
  adminAsidePages,
  InnerPages,
  publicPages,
  page404,
} from "../menu";

const WithOutAsidePagesContent = {
  Login: lazy(() => import("../pages/auth/Login")),
  ForgotPassword: lazy(() => import("../pages/auth/ForgotPassword")),
  Page404: lazy(() => import("../pages/Page404")),
  ResetPassword: lazy(() => import("../pages/auth/ResetPassword")),
  CheckEmail: lazy(() => import("../pages/auth/CheckEmail")),
  NoPermisison: lazy(() => import("../pages/auth/NoPermission")),
  Verify: lazy(() => import("../pages/auth/Verify")),
  VerifySuccess: lazy(() => import("../pages/auth/VerifySuccess")),
  Subscription: lazy(() => import("../pages/publicPages/Subscription")),
};

const AdminAsidePagesContent = {
  Home: lazy(() => import("../pages/adminPages/Home")),
  Events: lazy(() => import("../pages/adminPages/Events")),
  EventRequests: lazy(() => import("../pages/adminPages/EventRequests")),
  Categories: lazy(() => import("../pages/adminPages/Categories")),
  Invitation: lazy(() => import("../pages/adminPages/Invitation")),
  RentalTypes: lazy(() => import("../pages/adminPages/RentalTypes")),
  Customers: lazy(() => import("../pages/adminPages/Customers")),
  Users: lazy(() => import("../pages/adminPages/Users")),
  Organizations: lazy(() => import("../pages/adminPages/Organizations")),
  Settings: lazy(() => import("../pages/adminPages/Settings")),
  Profile: lazy(() => import("../pages/adminPages/Profile")),
  Attendees: lazy(() => import("../pages/adminPages/Attendees")),
  Roles: lazy(() => import("../pages/adminPages/Roles")),
  Permissions: lazy(() => import("../pages/adminPages/Permissions")),
  Sponsors: lazy(() => import("../pages/adminPages/Sponsors")),
  EventInvitations: lazy(() => import("../pages/adminPages/EventInvitations")),
  AssignSecurity: lazy(() => import("../pages/adminPages/AssignSecurity")),
  Attendance: lazy(() => import("../pages/adminPages/Attendance")),
  EmailTemplates: lazy(() => import("../pages/adminPages/EmailTemplates")),
  SharedEvents: lazy(() => import("../pages/adminPages/SharedEvents")),
  SharedContacts: lazy(() => import("../pages/adminPages/SharedContacts")),
};

const common = [
  //auth pages
  {
    path: authPages.Login.path,
    element: <WithOutAsidePagesContent.Login />,
    exact: true,
  },
  {
    path: authPages.ForgotPassword.path,
    element: <WithOutAsidePagesContent.ForgotPassword />,
    exact: true,
  },
  {
    path: authPages.ResetPassword.path,
    element: <WithOutAsidePagesContent.ResetPassword />,
    exact: true,
  },
  {
    path: authPages.CheckEmail.path,
    element: <WithOutAsidePagesContent.CheckEmail />,
    exact: true,
  },
  {
    path: authPages.NoPermission.path,
    element: <WithOutAsidePagesContent.NoPermisison />,
    exact: true,
  },
  {
    path: authPages.Verify.path,
    element: <WithOutAsidePagesContent.Verify />,
    exact: true,
  },
  {
    path: authPages.VerifySuccess.path,
    element: <WithOutAsidePagesContent.VerifySuccess />,
    exact: true,
  },
  {
    path: publicPages.Subscription.path,
    element: <WithOutAsidePagesContent.Subscription />,
    exact: true,
  },
  // {
  //   path: page404.path,
  //   element: <WithOutAsidePagesContent.Page404 />,
  //   exact: true,
  // },
];

const adminAsidePagesContent = [
  // pages in the Aside content
  {
    path: adminAsidePages.Home.path,
    element: <AdminAsidePagesContent.Home />,
    exact: true,
  },
  {
    path: adminAsidePages.Attendees.path,
    element: <AdminAsidePagesContent.Attendees />,
    exact: true,
  },
  {
    path: adminAsidePages.Invitation.path,
    element: <AdminAsidePagesContent.Invitation />,
    exact: true,
  },
  {
    path: adminAsidePages.Events.path,
    element: <AdminAsidePagesContent.Events />,
    exact: true,
  },
  {
    path: adminAsidePages.EventRequests.path,
    element: <AdminAsidePagesContent.EventRequests />,
    exact: true,
  },
  {
    path: adminAsidePages.EventSettings.items.Categories.path,
    element: <AdminAsidePagesContent.Categories />,
    exact: true,
  },
  {
    path: adminAsidePages.EventSettings.items.Sponsors.path,
    element: <AdminAsidePagesContent.Sponsors />,
    exact: true,
  },
  {
    path: adminAsidePages.EventSettings.items.EmailTemplates.path,
    element: <AdminAsidePagesContent.EmailTemplates />,
    exact: true,
  },
  {
    path: adminAsidePages.UserSettings.items.Users.path,
    element: <AdminAsidePagesContent.Users />,
    exact: true,
  },
  {
    path: adminAsidePages.UserSettings.items.Roles.path,
    element: <AdminAsidePagesContent.Roles />,
    exact: true,
  },
  {
    path: adminAsidePages.UserSettings.items.Permissions.path,
    element: <AdminAsidePagesContent.Permissions />,
    exact: true,
  },
  {
    path: adminAsidePages.Organizations.path,
    element: <AdminAsidePagesContent.Organizations />,
    exact: true,
  },
  {
    path: adminAsidePages.Security.items.AssignSecurity.path,
    element: <AdminAsidePagesContent.AssignSecurity />,
    exact: true,
  },
  {
    path: adminAsidePages.Security.items.Attendance.path,
    element: <AdminAsidePagesContent.Attendance />,
    exact: true,
  },
  {
    path: InnerPages.EventInvitations.path,
    element: <AdminAsidePagesContent.EventInvitations />,
    exact: true,
  },
  {
    path: InnerPages.SharedEvents.path,
    element: <AdminAsidePagesContent.SharedEvents />,
    exact: true,
  },
  {
    path: InnerPages.SharedContacts.path,
    element: <AdminAsidePagesContent.SharedContacts />,
    exact: true,
  },
  {
    path: InnerPages.Profile.path,
    element: <AdminAsidePagesContent.Profile />,
    exact: true,
  },
  {
    path: adminAsidePages.Settings.path,
    element: <AdminAsidePagesContent.Settings />,
    exact: true,
  },
];

export const adminContents = [...adminAsidePagesContent];
export const rentalCustomersContents = [];
export default common;
