import { apiSlice } from "../../app/api/apiSlice";

export const settingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSettings: builder.query({
      query: () => "/portal/setting/",
      transformResponse: (response) => {
        const { app_setting, portal_setting, system_setting, api_key_setting } =
          response.data;
        return {
          data: {
            app_setting,
            portal_setting,
            system_setting,
            api_key_setting,
          },
        };
      },
      providesTags: ["Settings"],
    }),
    getApiKeySetting: builder.query({
      query: () => "/portal/setting/api_key/",
      method: "GET",
    }),
    getTimezones: builder.query({
      query: () => "/portal/setting/all_timezones/",
      method: "GET",
      transformResponse: (response) => {
        return response.data.timezones;
      },
    }),
  }),
});

export const {
  useGetAllSettingsQuery,
  useUpdateSettingsMutation,
  useGetApiKeySettingQuery,
  useGetTimezonesQuery,
} = settingsApiSlice;
