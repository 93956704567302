import { noAccessReq } from "../menu";

export const checkRoutePermitted = (pages, path, permitted = false) => {
  pages.every(({ items, ...item }) => {
    if (items) {
      permitted = checkRoutePermitted(Object.values(items), path, permitted);
    } else if (item.path === path || noAccessReq.includes(path)) {
      permitted = true;
    }
    if (permitted) return false;
    return true;
  });

  return permitted;
};
