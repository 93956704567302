import { styled } from "@mui/system";
import { Box, ListItemIcon, Typography } from "@mui/material";
import { Icon } from "@mui/material";

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "1rem",
  paddingRight: "10px",
}));

export const StyledBackButton = styled(Icon)(({ theme }) => ({
  marginLeft: 16,
  color: theme.palette.primary.main,
  "&:hover": {
    cursor: "pointer",
  },
}));

export const StyledHeaderIcon = styled(ListItemIcon)(({ theme }) => ({
  marginLeft: 16,
  fontSize: "24px",
  color: theme.palette.primary.main,
}));
