import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/auth/authSlice";
import { useLoginMutation } from "../../features/auth/authApiSlice";
//material imports
import { styled, alpha } from "@mui/system";
import {
  Box,
  Paper,
  Typography,
  Button,
  Link,
  LinearProgress,
} from "@mui/material";

//component import
import { CustomInput } from "../../components/form-components/InputField";
import { StyledContainer } from "./ForgotPassword";
import jwtDecode from "jwt-decode";
import Toast from "../../components/Toast";
import StyledResponsiveBox from "../../components/form-components/StyledResponsiveBox";
import { apiSlice } from "../../app/api/apiSlice";
import CustomPasswordField from "../../components/form-components/CustomPasswordField";

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),

  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum"),
});

const Background = styled(Paper)({
  height: "100vh",
  background:
    "linear-gradient(180deg, #1A6D90 0%, rgba(26, 109, 144, 0.35) 100%)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledLink = styled(Link)(({ theme }) => ({
  fontSize: "10px",
  fontWeight: "100",
  fontStyle: "italic",
  marginTop: "1rem",
  marginButtom: "4rem",
  alignSelf: "flex-end",
}));

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})(({ theme, backgroundColor }) => ({
  backgroundColor: backgroundColor ? backgroundColor : "#1A6D90",
  color: "#fff",
  fontWeight: "500",
  transition: "all .2s",
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
  },
  "&:disabled": {
    backgroundColor: backgroundColor
      ? backgroundColor
      : theme.palette.primary.light,
    color: "white",
  },
}));

const Login = () => {
  const [errMsg, setErrMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [login, { isLoading, error }] = useLoginMutation();
  const dispatch = useDispatch();
  useEffect(() => dispatch(apiSlice.util.resetApiState()), []);

  //validate and submit
  const handleSubmit = async (values) => {
    try {
      const userData = await login({
        email: values.email,
        password: values.password,
      }).unwrap();

      const user_info = jwtDecode(userData.token.access);
      //saving refresh token to local storage

      localStorage.setItem("access", userData.token.access);
      localStorage.setItem("refresh", userData.token.refresh);

      dispatch(
        setCredentials({
          accessToken: userData.token.acccess,
          refreshToken: userData.token.refresh,
          user: user_info.username,
          role: user_info.role_name,
          isAuth: true,
        })
      );

      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.status) {
        // isLoading: true until timeout occurs
        setErrMsg("No Server Response");
      } else if (err.status >= 400) {
        setErrMsg(err.data.message);
      } else {
        setErrMsg("Login Failed");
      }
    }
  };

  // useEffect(() => {
  //   if (!isLoading && error) {
  //     setErrMsg(error.message);
  //   }
  // }, [error]);

  return (
    <Background>
      {isLoading && (
        <Box sx={{ position: "absolute", top: 0, width: "100%" }}>
          <LinearProgress color="secondary" />
        </Box>
      )}

      <StyledContainer elevation={3}>
        <Typography
          variant="h1"
          align="center"
          sx={{
            color: "primary.main",
            fontSize: "2rem",
            fontWeight: "600",
            lineHeight: "59px",
            marginBottom: "2rem",
          }}
        >
          LOGIN
        </Typography>
        {error && errMsg && <Toast message={errMsg} />}
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={loginSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Box component={Form} noValidate>
              <CustomInput
                name="email"
                required
                fullWidth
                id="email"
                label="Email"
                placeholder="example@gmail.com"
                type="text"
              />
              <CustomPasswordField
                name="password"
                required
                fullWidth
                id="password"
                label="Password"
              />
              <Box
                sx={{
                  alignSelf: "flex-end",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ width: "100%", display: "flex" }}>
                  <StyledResponsiveBox />
                  <StyledButton
                    disabled={isLoading}
                    sx={{ flex: 1, mt: 2 }}
                    type="submit"
                  >
                    Login
                  </StyledButton>
                </Box>
                <StyledLink component={RouterLink} to="/password/new">
                  Forgot your password?
                </StyledLink>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 4,
                  }}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                  >
                    AEM v0.1.0 Lucy
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                  >
                    Powered by AWURA
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Formik>
      </StyledContainer>
    </Background>
  );
};

export default Login;
