import { styled } from "@mui/system";
import { Box, Skeleton, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { getEventDetails } from "../../../service/EventService";

const StyledEventInvitationsHeader = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  paddingBottom: "1rem",
}));

const StyledBackButton = styled(KeyboardBackspaceIcon)(({ theme }) => ({
  marginLeft: 16,
  color: theme.palette.primary.main,
  "&:hover": {
    cursor: "pointer",
  },
  marginBottom: 2,
}));

const StyledSkeleton = styled(Skeleton)(() => ({
  display: "inline-block",
  width: "170px",
}));

const EventInvitationsHeader = ({
  onSendToSelected,
  onSendForAll,
  sendAllDisabled = false,
  sendSelectedDisabled = false,
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let event_id = searchParams.get("event_id");
  const [event, setEvent] = useState(null);
  const [accessToken] = useLocalStorage("access", "");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchEventDetails = async () => {
      setIsLoading(true);
      const res = await getEventDetails(accessToken, event_id);
      setEvent(res);
      setIsLoading(false);
    };
    fetchEventDetails().catch(console.error);
  }, [event_id]);

  return (
    <StyledEventInvitationsHeader>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "start",
          width: "500px",
          paddingTop: 4,
        }}
      >
        <StyledBackButton onClick={() => navigate(-1)} />
        <Box sx={{ display: "flex" }}>
          <Typography
            ml={2}
            mr={0}
            variant="h1"
            fontWeight={700}
            fontSize={22}
            sx={{ alignSelf: "flex-start" }}
            color="primary.main"
          >
            {isLoading ? (
              <StyledSkeleton variant="text" />
            ) : event ? (
              event["name"] + " Attendees"
            ) : null}
          </Typography>
          <Typography
            color={
              event?.status === "PAST"
                ? "orange"
                : event?.status === "ONGOING"
                ? "#22861e"
                : event?.status === "POSTPONED_UNDECIDED" ||
                  event?.status === "POSTPONED"
                ? "#ffa700"
                : event?.status === "UPCOMING"
                ? "blue"
                : event?.status === "CANCELED"
                ? "#ff0000"
                : ""
            }
            pl={2}
          >
            {event?.status}
          </Typography>
        </Box>
      </Box>
    </StyledEventInvitationsHeader>
  );
};

export default EventInvitationsHeader;
