import { baseUrl } from "../app/api/apiSlice";

// Function that will be called to refresh authorization
export const refreshAuthLogic = (failedRequest) => {
  const refreshToken = window.localStorage?.getItem("refresh");
  return fetch(`${baseUrl}/api/token/refresh/`, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      refresh: refreshToken,
    }),
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("Expired");
      }
      return res.json();
    })
    .then((data) => {
      window.localStorage.setItem("access", data.access);
      window.localStorage.setItem("refresh", data.refresh);
      failedRequest.response.config.headers["Authorization"] =
        "JWT " + data.access;
      return Promise.resolve();
    })
    .catch((err) => {
      window.localStorage.removeItem("access");
      window.localStorage.removeItem("refresh");
      window.location.replace("/login");
      return Promise.reject();
    });
};
