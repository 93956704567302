import { Box, IconButton, Typography } from "@mui/material";
import { color, styled } from "@mui/system";
import React from "react";
import { FaPlus } from "react-icons/fa";
import StyledHeaderText from "../../../components/styles/StyledHeaderText";
import { useModal } from "../../../contexts/modalContext";
import { StyledButton } from "../../../pages/auth/Login";

const StyledRentalTypeHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "1rem",
}));

const RentalTypeHeader = () => {
  const { toggleModal } = useModal();

  return (
    <StyledRentalTypeHeader>
      <StyledHeaderText variant="h1"> Rental Types</StyledHeaderText>
      <StyledButton onClick={toggleModal.bind(this, "modal1")} sx={{ mr: 2 }}>
        <Typography variant="p">Add Rental Types</Typography>
      </StyledButton>
    </StyledRentalTypeHeader>
  );
};

export default RentalTypeHeader;
